export enum BaseButtonKindEnum {
  PRIMARY = "primary",
  SECONDARY = "secondary",
  SUCCESS = "success",
  DANGER = "danger",
  WARNING = "warning",
  INFO = "info",
  LIGHT = "light",
  DARK = "dark",
  LINK = "link",
  NAKED = "naked",
  WHITE = "white",
}

export enum BaseButtonSizeEnum {
  SM = "sm",
  BODY = "body",
  LG = "lg",
}

export enum BaseButtonVariantEnum {
  DARK = "dark",
  LIGHT = "light",
  PRIMARY = "primary",
}

export enum BaseButtonWeightEnum {
  MEDIUM = "medium",
  NORMAL = "normal",
  SEMIBOLD = "semibold",
  BOLD = "bold",
}

export enum BaseButtonTypeEnum {
  BUTTON = "button",
  SUBMIT = "submit",
  RESET = "reset",
}
