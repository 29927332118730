import { useApiClient } from "@/composables/useApiClient";
import { useSession } from "@/composables/useSession";

export function useIncognito() {
  const incognitoStore = useIncognitoStore();
  const queryClient = useQueryClient();
  const { refetchUser } = useSession();

  const { schoolClasses: schoolClassesService } = useApiClient();

  const loginAsPupil = async (pupilId: string) => {
    // Capture the teacher's URL using router's current route if not already set.
    if (!incognitoStore.path) {
      incognitoStore.path = window.location.href; // router paths won't work here
    }
    // Mark impersonation without overwriting the stored teacher path.
    if (!incognitoStore.userId) {
      incognitoStore.userId = pupilId;
    }

    try {
      await schoolClassesService.impersonatePupil({
        id: pupilId,
      });

      queryClient.removeQueries();

      await refetchUser();
    } catch (error) {
      incognitoStore.reset();
      console.error(error);
      throw error;
    }
  };

  const { sessions: sessionsService } = useApiClient();

  const logout = async () => {
    try {
      await sessionsService.exitIncognitoMode();

      queryClient.removeQueries();

      await refetchUser();
      if (incognitoStore.path) {
        window.location.href = incognitoStore.path; // router.push doesn't work here
      }
    } catch (error) {
      console.error(error);
    } finally {
      incognitoStore.reset();
    }
  };

  return {
    loginAsPupil,
    logout,
  };
}
