import { defineStore } from "pinia";

type IncognitoStoreType = {
  path?: string;
  userId?: string;
};

export const useIncognitoStore = defineStore("incognito", {
  state: (): IncognitoStoreType => {
    return {
      path: undefined,
      userId: undefined,
    };
  },
  actions: {
    start(userId: string) {
      this.path = window.location.href;
      this.userId = userId;
    },
    reset() {
      this.path = undefined;
      this.userId = undefined;
    },
  },
});
