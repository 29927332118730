<script setup lang="ts">
import blueskyLogo from "@/images/brand-bluesky.svg";
import dataProtectionImageDe from "@/images/data_protection_de.png";
import dataProtectionImageEn from "@/images/data_protection_en.png";

import logoUrlDesktop from "/images/fobizz_logo_4c.svg";

const { t } = useI18n();
const { fobizzRootUrl } = useUrlsStore();
const userStore = useUserStore();
const locale = userStore.user?.international ? "en" : "de";

const props = withDefaults(
  defineProps<{
    withMainMargin?: boolean;
    mini?: boolean;
  }>(),
  {
    withMainMargin: true,
    mini: false,
  }
);

const dataProtectionImage = computed(() => {
  if (userStore.user?.international) {
    return dataProtectionImageEn;
  }

  return dataProtectionImageDe;
});

const enableMiniFooter = computed(() => {
  return locale === "en" || props.mini;
});

const enableNewFooter = computed(() => userStore.isFeatureEnabled("footer"));
</script>

<template>
  <footer
    v-if="enableNewFooter"
    class="footer"
    :class="{ 'footer--mini': enableMiniFooter }"
  >
    <div
      class="container"
      :class="{ 'main-margin': withMainMargin && enableMiniFooter }"
    >
      <div class="footer__container">
        <div class="footer__logo-wrapper">
          <div class="footer__logo">
            <a :href="fobizzRootUrl" aria-label="Go to homepage" loading="lazy">
              <img :src="logoUrlDesktop" width="160" alt="" />
            </a>
          </div>
          <div v-if="!enableMiniFooter" class="footer__socials">
            <a
              href="https://www.facebook.com/fobizz"
              target="_blank"
              rel="noopener"
              aria-label="Facebook"
              class="footer__social-link"
            >
              <i class="bi bi-facebook"></i>
            </a>
            <a
              href="https://www.instagram.com/fobizz_com"
              target="_blank"
              rel="noopener"
              aria-label="Instagram"
              class="footer__social-link"
            >
              <i class="bi bi-instagram"></i>
            </a>
            <a
              href="https://www.linkedin.com/company/fobizz"
              target="_blank"
              rel="noopener"
              aria-label="LinkedIn"
              class="footer__social-link"
            >
              <i class="bi bi-linkedin"></i>
            </a>
            <a
              href="https://bsky.app/profile/fobizz.bsky.social"
              target="_blank"
              rel="noopener"
              aria-label="Bluesky"
              class="footer__social-link"
            >
              <img
                :src="blueskyLogo"
                width="24"
                height="24"
                alt="Bluesky"
                style="margin-top: -4px"
              />
            </a>
          </div>
        </div>
        <div v-if="enableMiniFooter" class="footer__mini-columns">
          <a
            target="_blank"
            rel="noopener"
            href="https://tools.fobizz.com/help"
            class="footer__link"
            >{{ t("mainFobizzNavbar.myfobizz.links.contact") }}</a
          >

          <a
            target="_blank"
            rel="noopener"
            href="https://plattform.fobizz.com/imprints"
            class="footer__link"
            >{{ t("footer.imprint") }}</a
          >
          <a
            target="_blank"
            rel="noopener"
            href="https://plattform.fobizz.com/data_protections"
            class="footer__link"
            >{{ t("footer.privacyPolicy") }}</a
          >
          <a
            target="_blank"
            rel="noopener"
            href="https://plattform.fobizz.com/terms_and_conditions"
            class="footer__link"
            >{{ t("footer.tAndC") }}</a
          >
        </div>
        <div v-else class="footer__columns">
          <div class="footer__list-wrapper">
            <h3 class="footer__heading">
              <a :href="t('footer.urls.aiAndTools')">{{
                t("footer.aiAndTools")
              }}</a>
            </h3>
            <ul class="footer__list">
              <li>
                <a
                  :href="t('footer.urls.aiAndToolsHelp')"
                  class="footer__link"
                  >{{ t("footer.aiAndToolsHelp") }}</a
                >
              </li>
              <li>
                <a
                  :href="t('footer.urls.useToolsWithStudents')"
                  class="footer__link"
                  >{{ t("footer.useToolsWithStudents") }}</a
                >
              </li>
              <li>
                <a :href="t('footer.urls.aiGuidelines')" class="footer__link">{{
                  t("footer.aiGuidelines")
                }}</a>
              </li>
            </ul>
          </div>

          <div class="footer__list-wrapper">
            <h3 class="footer__heading">
              <a :href="t('footer.urls.courses')">{{ t("footer.courses") }}</a>
            </h3>
            <ul class="footer__list">
              <li>
                <a :href="t('footer.urls.coursesHelp')" class="footer__link">{{
                  t("footer.coursesHelp")
                }}</a>
              </li>
              <li>
                <a
                  :href="t('footer.urls.enrollmentHelp')"
                  class="footer__link"
                  >{{ t("footer.enrollmentHelp") }}</a
                >
              </li>
              <li>
                <a
                  :href="t('footer.urls.certificatesHelp')"
                  class="footer__link"
                  >{{ t("footer.certificatesHelp") }}</a
                >
              </li>
            </ul>
          </div>

          <div class="footer__list-wrapper">
            <h3 class="footer__heading">
              <a :href="t('footer.urls.teachingMaterial')">{{
                t("footer.teachingMaterial")
              }}</a>
            </h3>
            <ul class="footer__list">
              <li>
                <a
                  :href="t('footer.urls.teachingMaterialHelp')"
                  class="footer__link"
                  >{{ t("footer.teachingMaterialHelp") }}</a
                >
              </li>
              <li>
                <a
                  :href="t('footer.urls.shareWithStudents')"
                  class="footer__link"
                  >{{ t("footer.shareWithStudents") }}</a
                >
              </li>
              <li>
                <a
                  :href="t('footer.urls.shareWithColleagues')"
                  class="footer__link"
                  >{{ t("footer.shareWithColleagues") }}</a
                >
              </li>
            </ul>
          </div>

          <div class="footer__list-wrapper">
            <h3 class="footer__heading">
              <a :href="t('footer.urls.helpAndContact')">{{
                t("footer.helpAndContact")
              }}</a>
            </h3>
            <ul class="footer__list">
              <li>
                <a
                  :href="t('footer.urls.technicalHelp')"
                  class="footer__link"
                  >{{ t("footer.technicalHelp") }}</a
                >
              </li>
              <li>
                <a
                  :href="t('footer.urls.gettingStarted')"
                  class="footer__link"
                  >{{ t("footer.gettingStarted") }}</a
                >
              </li>
              <li>
                <a
                  :href="t('footer.urls.commonQuestions')"
                  class="footer__link"
                  >{{ t("footer.commonQuestions") }}</a
                >
              </li>
            </ul>
          </div>

          <div class="footer__list-wrapper">
            <h3 class="footer__heading">
              <a :href="t('footer.urls.licenses')">{{
                t("footer.licenses")
              }}</a>
            </h3>
            <ul class="footer__list">
              <li>
                <a
                  :href="t('footer.urls.singleLicensePrices')"
                  class="footer__link"
                  >{{ t("footer.singleLicensePrices") }}</a
                >
              </li>
              <li>
                <a
                  :href="t('footer.urls.schoolLicensePrices')"
                  class="footer__link"
                  >{{ t("footer.schoolLicensePrices") }}</a
                >
              </li>
              <li>
                <a :href="t('footer.urls.freeTrial')" class="footer__link">{{
                  t("footer.freeTrial")
                }}</a>
              </li>
            </ul>
          </div>

          <div class="footer__list-wrapper">
            <h3 class="footer__heading">
              <a :href="t('footer.urls.partners')">{{
                t("footer.partners")
              }}</a>
            </h3>
            <ul class="footer__list">
              <li>
                <a :href="t('footer.urls.partnersHelp')" class="footer__link">{{
                  t("footer.partnersHelp")
                }}</a>
              </li>
              <li>
                <a :href="t('footer.urls.toTeach')" class="footer__link">{{
                  t("footer.toTeach")
                }}</a>
              </li>
            </ul>
          </div>

          <div class="footer__list-wrapper">
            <h3 class="footer__heading">
              {{ t("footer.company") }}
            </h3>
            <ul class="footer__list">
              <li>
                <a :href="t('footer.urls.aboutUs')">{{
                  t("footer.aboutUs")
                }}</a>
              </li>
              <li>
                <a :href="t('footer.urls.jobs')" class="footer__link">{{
                  t("footer.jobs")
                }}</a>
              </li>
              <li>
                <a :href="t('footer.urls.team')" class="footer__link">{{
                  t("footer.team")
                }}</a>
              </li>
            </ul>
          </div>

          <div class="footer__list-wrapper">
            <h3 class="footer__heading">{{ t("footer.legal") }}</h3>
            <ul class="footer__list">
              <li>
                <a
                  :href="t('footer.urls.imprint')"
                  target="_blank"
                  rel="noopener"
                  class="footer__link"
                  >{{ t("footer.imprint") }}</a
                >
              </li>
              <li>
                <a
                  :href="t('footer.urls.privacyPolicy')"
                  target="_blank"
                  rel="noopener"
                  class="footer__link"
                  >{{ t("footer.privacyPolicy") }}</a
                >
              </li>
              <li>
                <a
                  :href="t('footer.urls.termsAndConditions')"
                  target="_blank"
                  rel="noopener"
                  class="footer__link"
                  >{{ t("footer.tAndC") }}</a
                >
              </li>
            </ul>
          </div>

          <div class="footer__list-wrapper">
            <div class="footer__protection">
              <img
                class="footer__image"
                :alt="t('footer.alternativeText')"
                :src="dataProtectionImage"
                loading="lazy"
                width="210"
              />
            </div>
          </div>
        </div>
        <div v-if="enableMiniFooter" class="footer__protection">
          <img
            class="footer__image"
            :alt="t('footer.alternativeText')"
            :src="dataProtectionImage"
            loading="lazy"
            width="210"
          />
        </div>
      </div>
    </div>
    <div v-if="!enableMiniFooter" class="footer__copyright">
      <div class="container">
        <strong class="fw-medium"
          >Made in Germany with 🤍 for all educators</strong
        >
        © fobizz | 101skills GmbH
      </div>
    </div>
  </footer>
  <TheFooterOld v-else />
</template>

<style lang="scss" scoped>
.footer {
  border-top: 2px solid;
  border-image: url("@/images/rainbow-border.svg") 1;
  background-color: $color-white;

  &__container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: $spacer-3;
    justify-items: center;
    padding: $spacer-6 0;

    @include media-breakpoint-down(xl) {
      grid-template-columns: 1fr;
      justify-items: start;
      gap: $spacer-5;
    }

    @include media-breakpoint-down(md) {
      grid-template-columns: 1fr;
      align-items: start;
      gap: $spacer-5;
    }
  }

  &--mini &__container {
    padding: $spacer-4 0;
    grid-template-columns: repeat(3, 1fr);

    @include media-breakpoint-down(xl) {
      grid-template-columns: repeat(3, 1fr);
      justify-items: center;
    }

    @include media-breakpoint-down(md) {
      grid-template-columns: 1fr;
      align-items: start;
      gap: $spacer-5;
    }
  }

  &__logo-wrapper {
    display: flex;
    flex-direction: column;
    justify-self: start;
    gap: $spacer-3;
  }

  &--mini &__logo-wrapper {
    align-self: center;
  }

  &__logo {
    display: flex;
    justify-self: start;

    @include media-breakpoint-down(md) {
      align-items: start;
      justify-self: start;
    }
  }

  &--mini &__logo {
    align-items: center;
    justify-self: start;
  }

  &__socials {
    display: flex;
    gap: $spacer-3;
  }

  &__social-link {
    font-size: 1.4rem;
  }

  &__protection {
    display: flex;
    align-items: center;
    justify-self: end;
    margin-left: -8px;

    @include media-breakpoint-down(md) {
      align-items: start;
      justify-self: start;

      img {
        margin-left: 0;
      }
    }
  }

  &--mini &__protection {
    img {
      margin-right: -8px;
    }

    @include media-breakpoint-down(md) {
      img {
        margin-left: 0;
      }
    }
  }

  &__copyright {
    display: flex;
    justify-content: center;
    padding: $spacer-2;
    background-color: $primary;
    color: $white;
    text-align: center;
    font-size: $font-size-sm;
  }

  &__columns {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 3rem;

    @include media-breakpoint-down(lg) {
      grid-template-columns: repeat(2, 1fr);
    }

    @include media-breakpoint-down(md) {
      grid-template-columns: 1fr;
    }
  }

  &__mini-columns {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 2rem;
    place-items: center center;

    @include media-breakpoint-down(xl) {
      grid-template-columns: repeat(2, 1fr);
      gap: 0 2rem;
      justify-items: start;
    }

    @include media-breakpoint-down(md) {
      grid-template-columns: 1fr;
      gap: $spacer-2;
      align-items: start;
      justify-self: start;
    }
  }

  &__link {
    color: $body-color;
    text-decoration: none;
    white-space: nowrap;
  }

  &__list-wrapper {
    display: flex;
    flex-direction: column;
    gap: $spacer-2;
  }

  &__heading {
    font-size: $font-size-lg;
    font-weight: $font-weight-medium;

    a {
      color: $body-color;
      text-decoration: none;
    }
  }

  &__list {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      padding: 0;
      margin-bottom: $spacer-2;
    }

    a {
      color: $body-color;
      text-decoration: none;
    }
  }

  .image {
    max-width: 100%;
    height: auto;

    @include media-breakpoint-down(md) {
      max-width: 120px;
    }
  }
}
</style>
