import { useOnboardingCheck } from "@/composables/useOnboardingCheck";
import { useUserStore } from "@/stores/user";
import ahoy from "ahoy.js";
import { RouteLocationNormalized, NavigationGuardNext } from "vue-router";

export async function routerBeforeEach(
  to: RouteLocationNormalized,
  // @ts-ignore: required argument but ot needed now
  from: RouteLocationNormalized,
  next: NavigationGuardNext
): Promise<void> {
  const userStore = useUserStore();
  const { checkOnboarding } = useOnboardingCheck();

  if (checkOnboarding(to)) {
    next(false);
    return;
  }

  // Redirect to school class if pupil and on home route
  if (
    userStore.isPupil &&
    userStore.user?.schoolClassId &&
    to.name === "home"
  ) {
    next({
      name: "pupil-school-class-show",
      params: { id: userStore.user.schoolClassId },
      query: { token: userStore.user.schoolClassToken },
    });
    return;
  }

  // Analytics
  ahoy.trackView();

  // Redirect if page requires teacher but current user is pupil
  if (to.meta.requiresTeacher && userStore.isPupil) {
    next({ name: "home" });
    return;
  }

  // Redirect if page requires pupil but current user is not pupil
  if (to.meta.requiresPupil && !userStore.isPupil) {
    next({ name: "home" });
    return;
  }

  // Redirect to login page if not logged in (for teacher)
  if (
    to.meta.requiresAuth &&
    !userStore.isAuthenticated &&
    !userStore.isVisitor &&
    !userStore.isPupil
  ) {
    next({
      name: "login",
      query: { redirectUri: window.location.href },
    });
    return;
  }

  // Enable galleryMode if URL contains public_token
  const galleryModeStore = useIsGalleryModeStore();
  if (to.query.public_token) {
    galleryModeStore.updateGalleryMode(true);
  } else {
    galleryModeStore.updateGalleryMode(false);
  }

  next();
}
