/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FileInput } from '../models/FileInput';
import type { FileSharingFile } from '../models/FileSharingFile';
import type { ItemInput } from '../models/ItemInput';
import type { PaginatedFileSharingFiles } from '../models/PaginatedFileSharingFiles';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class FileSharingService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Create File Attachement
     * @returns void
     * @throws ApiError
     */
    public createFileSharingFileAttachment({
        id,
        requestBody,
    }: {
        id: string,
        requestBody: {
            file?: FileInput;
        },
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/file_sharing/files/{id}/attachment',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `unauthorized`,
                422: `unprocessable_entity`,
            },
        });
    }
    /**
     * Delete File Attachment
     * @returns void
     * @throws ApiError
     */
    public deleteFileSharingFileAttachment({
        id,
        shrineId,
    }: {
        id: string,
        shrineId: string,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/file_sharing/files/{id}/attachment',
            path: {
                'id': id,
            },
            query: {
                'shrine_id': shrineId,
            },
            errors: {
                401: `unauthorized`,
                403: `forbidden`,
                422: `unprocessable_entity`,
            },
        });
    }
    /**
     * List of Files
     * @returns PaginatedFileSharingFiles successful
     * @throws ApiError
     */
    public listFileSharingFiles(): CancelablePromise<PaginatedFileSharingFiles> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/file_sharing/files',
            errors: {
                401: `unauthorized`,
            },
        });
    }
    /**
     * File Details
     * @returns FileSharingFile successful
     * @throws ApiError
     */
    public getFileSharingFile({
        id,
        token,
        password,
    }: {
        id: string,
        token?: string,
        password?: string,
    }): CancelablePromise<FileSharingFile> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/file_sharing/files/{id}',
            path: {
                'id': id,
            },
            query: {
                'token': token,
                'password': password,
            },
            errors: {
                400: `bad request`,
                401: `unauthorized`,
                403: `forbidden`,
                404: `not found`,
            },
        });
    }
    /**
     * Update File
     * @returns FileSharingFile successful
     * @throws ApiError
     */
    public updateFileSharingFile({
        id,
        requestBody,
    }: {
        id: string,
        requestBody: {
            fileSharingFile?: ItemInput;
        },
    }): CancelablePromise<FileSharingFile> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/file_sharing/files/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `unauthorized`,
                403: `forbidden`,
                404: `not found`,
            },
        });
    }
}
