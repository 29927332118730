<script lang="ts">
export default {
  name: "IncognitoOverlay",
};
</script>

<script lang="ts" setup>
const { t } = useI18n();
const userStore = useUserStore();
const incognitoStore = useIncognitoStore();
const { logout } = useIncognito();

const router = useRouter();

const handleLogout = async () => {
  await logout();

  if (incognitoStore.path) {
    await router.push(incognitoStore.path);
  } else if (userStore.isAdmin && incognitoStore.userId) {
    window.location.href = `/backoffice/users/${incognitoStore.userId}`;
  } else {
    await router.push("/");
  }
};

// called when still in incognito mode and the state is filled with incognito user
</script>
<template>
  <div
    v-if="userStore.isIncognito"
    class="incognito"
    data-test="hint-invitee-impersonation"
  >
    <div class="alert alert-warning p-2 flex-column">
      <p class="mb-2">
        {{ t("incognito.signedInAs") }}
        <br />
        <strong :title="userStore.name">{{ userStore.shortName }}</strong>
      </p>
      <button
        class="btn btn-outline-danger btn-sm"
        data-cy="button-stop_invitee_impersonation"
        @click="handleLogout"
      >
        {{ t("incognito.stop") }}
      </button>
    </div>
  </div>
</template>
